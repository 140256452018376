.layout-container {
    display: flex;
}

/* Header.css */
.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    width: 100%;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img {
    height: 30px;
    margin-right: 10px;
}

.header-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.header-nav a {
    text-decoration: none;
    color: var(--secondary-color);
    font-size: 14px;
}

.header-search {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-search input {
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    width: 200px;
}

.header-icons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.header-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: var(--cor-principal);
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}

.header-logo div {
    color: var(--primary-color);
    font-family: 'Exo', sans-serif;
    font-weight: 200;
}

.header-logo div span {
    color: #5379fa !important;
}

.header-tools {
    position: absolute;
    top: 55px;
    right: 0;
    width: 300px;
    background: #fff;
    box-shadow: 4px 5px 6px 5px rgba(0, 0, 0, 0.1);
    z-index: 99999999;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.links {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

.links a {
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
    transition: 0.5s;
}

.links a:hover {
    opacity: 0.7;
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link a {
    color: #000;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.link-active a {
    font-weight: 700y;
    color: #5379fa !important;
}

.link:hover .nav-tooltip {
    /* visibility: visible;
    opacity: 1; */
}

.nav-tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 1000;

    top: 3.5rem;
    left: .5rem;
    color: #fff;
    height: 30px;
    display: flex;
    align-items: center;
}

.dashboard-container {
    padding: 2rem;
    background: #fff;
    min-height: 100vh;
    border-radius: 20px 20px 0px 0px;
}

.table tr.selecao {
    font-size: 10px !important;
}

.table tr.selecao:hover td {
    background-color: #0f0c29 !important;
    color: #fff;
    cursor: pointer;
}

.transparent-dropdown-menu {
    cursor: pointer;
    transition: .5s;
}

.transparent-dropdown-menu:hover {
    opacity: 0.7;
}

.header-nav .dropdown .transparent-dropdown-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.btn-menu {
    background: none;
    color: var(--primary-color);
    transition: 0.5s;
}

.btn-menu:hover {
    opacity: .4;
}

.container-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 350px;
    height: 100vh;
    overflow: auto;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transform: translateX(-100%);
    transition: transform 0.4s ease, opacity 0.4s ease;
}

.open-nav {
    transform: translateX(0);
    opacity: 1;
    left: 0;
    box-shadow: 1px 2px 20px #888888;
}

.flex-align-end {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
}

.link a {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.link {
    border-top: 1px solid #f2f2f2;
    padding: 1rem;
}

.header-simples {
    width: 100%;
    height: 50px;
    background-color: var(--cor-principal) !important;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-top: 20px;
}