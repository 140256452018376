/* * {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: sans-serif;
}

a {
    color: dodgerblue;
    font-size: 14px;
    display: block;
}

.login-title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-page {
    display: flex;
}

.notice {
    font-size: 13px;
    text-align: center;
    color: #000;
}

.login {
    width: 40%;
    height: 100vh;
    background: #FFF;
    padding: 70px;
}

.login a {
    margin-top: 25px;
    text-align: center;
}

.form-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}

.form-login label {
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 20px;
    display: block;
    color: #000;
}

.input-email,
.input-password {
    width: 100%;
    background: #ededed;
    border-radius: 10px;
    margin: 4px 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="email"],
input[type="password"],
input[type="text"] {
    width: 100%;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 4px 0;
    outline: none;
    margin-left: 5px;
}

button[type="submit"] {
    width: 100%;
    border: 0;
    border-radius: 10px;
    padding: 14px;
    background: dodgerblue;
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    transition: ease all 0.3s;
}

button[type="submit"]:hover {
    opacity: 0.9;
    border-radius: 0px;
}

.background {
    width: 60%;
    padding: 40px;
    height: 100vh;
    background: linear-gradient(60deg, dodgerblue, rgba(0, 0, 0, 0.7)), url('../../assets/logo_login.png') center no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: center;
    flex-direction: row;
}

.background h1 {
    max-width: 420px;
    color: #FFF;
    text-align: right;
    padding: 0;
    margin: 0;
}

.background p {
    max-width: 650px;
    color: #1a1a1a;
    font-size: 15px;
    text-align: right;
    padding: 0;
    margin: 15px 0 0 0;
}

.created {
    margin-top: 40px;
    text-align: center;
}

.created p {
    font-size: 13px;
    font-weight: bold;
    color: dodgerblue;
}

.created a {
    color: dodgerblue;
    font-weight: normal;
    text-decoration: none;
    margin-top: 0;
}

.checkbox label {
    display: inline;
    margin: 0;
}

.version {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
}

@media (max-width: 780px) {
    .background {
        width: 40%;
    }

    .login {
        width: 60%;
    }
}

@media (max-width: 580px) {
    .background {
        display: none;
    }

    .login {
        width: 100%;
    }
} */

.backgroud-login {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-page {
    width: 80%;
    height: 500px;
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 8px 20px 3px rgba(0, 0, 0, 0.7);
}

.left-container-login {
    width: 30%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.right-container-login {
    width: 70%;
    height: 100%;
    background-image: url('https://images.unsplash.com/photo-1519408469771-2586093c3f14?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2147&q=80');
    border-image: fill 0 linear-gradient(#0003,#000);
    background-position: center;
    background-color: transparent;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px; 
    color: #fff;
    font-weight: 700;
}