* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --hue: 223;
    --bg: rgba(0, 0, 0, 0.8);
    --fg: hsl(var(--hue), 10%, 10%);
}

.pl_container {
    position: fixed;
    width: 100%;
    background-color: #000755;
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
    color: var(--fg);
    font: 1em/1.5 sans-serif;
    height: 100vh;
    display: grid;
    place-items: center;
    transition: 0.6s;
    z-index: 10000;
}

.pl_container {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dots-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dots-loader div {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 1.2s infinite ease-in-out both;
}

.dots-loader div:nth-child(1) {
    animation-delay: -0.32s;
}

.dots-loader div:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}