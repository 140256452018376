* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Jost', sans-serif;
    background: #000755
}

:root {
    --bgColor--: #5161CE;
    --cor-principal: #3d4195;
}

.custom-modal .modal-dialog {
    width: 80%;
    max-width: 80%;
}

.button-container-grid {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
}

.button-container-item {
    flex-grow: 1;
    flex-basis: 50;
}

button {
    opacity: 0.8 !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.previsualizar-container {
    background: #fff;
    width: 100%;
    min-height: 100vh;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 9999;
    left: 0;
    padding: 2rem;
}

.previsualizar-close-button {
    text-align: end;
    transition: 0.5s;
    cursor: pointer;
}

.previsualizar-close-button:hover {
    opacity: 0.7;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.styled-table thead tr {
    background-color: #4CAF50;
    color: white;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
    text-align: center;
}

.styled-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
    background-color: #f1f1f1;
}

.btn-info,
.btn-success,
.btn-warning,
.btn-primary {
    background-color: var(--cor-principal) !important;
    color: #fff !important;
    font-weight: 600 !important;
    opacity: 1;
    transition: 0.5s;
    cursor: pointer;
    border: none;
}

.btn-info:hover,
.btn-success:hover,
.btn-warning:hover,
.btn-primary:hover {
    opacity: 0.5;
}

/*TIME LINE*/
.timeline {
    display: flex;
    flex-direction: column;
    margin: 20px;
    position: relative;
    justify-content: start;
    height: 93vh;
    overflow: auto;
    padding-top: 1.5rem;
    width: 100%;
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    width: 2px;
    height: 130%;
    background-color: #888;
    z-index: -1;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
    transition: .5s;
}

.timeline-item:hover {
    opacity: .4;
}

.avatar {
    width: 100px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.content {
    background-color: #1E1E1E;
    color: white;
    border-radius: 8px;
    padding: 10px;
    position: relative;
}

.tags {
    margin-top: 8px;
}

.tag {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    margin-right: 6px;
}

.tag.bug {
    background-color: #ff4d4d;
    color: white;
}

.tag.accessibility {
    background-color: #6c63ff;
    color: white;
}

.time {
    font-size: 12px;
    color: #888;
    margin-top: 4px;
}

.detalhes-timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-details {
    width: 100%;
    padding: 2rem;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #888888;
    position: sticky;
    top: 2rem;
    z-index: 1;
    background-color: white;
}

.multi-select {
    width: 200px;
    position: relative;
}

.dropdown-header {
    padding: 7px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.arrow.down {
    transform: rotate(45deg);
}

.dropdown-list {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
    display: flex;
    align-items: center;
}

.selected-options {
    margin-top: 10px;
}